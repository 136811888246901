.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	&--item {
		display: inline-block;
		margin-right: 20px;
	}
	&--link {
		display: block;
	}
}
