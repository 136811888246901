.l-listing {
	margin-top: min(400px, 30vw);

	&__books {
		margin-top: 180px;
	}

	&--pagination {
		display: flex;
		margin-top: calc(var(--p-rhythm) * 2);
		justify-content: space-between;
		width: min(480px, 40%);

		@include to-md {
			width: 100%;
		}
	}
}
