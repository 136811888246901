.news-listing {
	position: relative;
	display: grid;
	grid-template-columns: auto 100px;
	align-items: center;
	padding: var(--p-rhythm) 0;

	&--date {
		font-size: var(--fs-body-lg);
		margin-bottom: var(--p-rhythm);
		color: var(--accent-colour);
	}

	&--title {
		font-size: var(--fs-heading);
	}
	&--text {
		margin-right: var(--p-rhythm);
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__no-image {
			grid-column: 1/-1;
		}
	}

	@include to-md {
		&--text {
			padding: var(--p-rhythm) 0;
		}
	}

	@include from-md {
		grid-template-columns: auto 250px;

		&--image {
			height: 250px;
		}
	}

	@include from-lg {
		max-width: 80%;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: var(--accent-colour);
		opacity: 20%;
	}
}
