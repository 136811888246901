.book-listing {
	position: relative;
	display: flex;
	flex-direction: column-reverse;

	&--subheading {
		font-size: var(--fs-subheading) !important;
		margin-bottom: calc(var(--p-rhythm) * 1.5);
	}

	&--text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	&--heading {
		font-size: var(--fs-heading);
	}

	&--summary {
		margin: calc(var(--p-rhythm) * 1.5) 0;
		line-height: 2;
	}

	& + & {
		margin-top: 160px;
	}

	@include to-md {
		&--image {
			margin-bottom: 64px;
		}
		&--subheading {
			margin-bottom: 36px;
		}

		&--summary {
			line-height: 1.5;
		}

		&__home {
			.book-listing {
				&--summary {
					margin-bottom: 0;
				}
			}
		}
	}

	@include from-md {
		grid-template-columns: 1fr 1fr;
		display: grid;

		&--heading {
			margin-top: 0;
		}

		&--image {
			padding-left: var(--p-rhythm);
			& img {
				margin-left: auto;
				max-width: 600px;
			}
		}

		& + & {
			margin-top: cvar(--p-rhythm-lg);
		}

		&__home {
			.book-listing {
				&--text {
					max-width: calc((100% / 3) * 2);
				}
				&--image {
					padding-left: 0;

					& img {
						margin-left: 0;
					}
				}
			}
		}
	}
}
