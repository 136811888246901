.card {
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&--image {
	}

	&--details {
		padding: $padding-rythm-sm;
		background: white;
	}

	&--title {
	}
	&--subtitle {
	}

	&:hover {
	}
}

@include from-md {
	.card {
		&--title {
		}
	}
}
