@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: var(--heading-font);
	margin: 0;
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: var(--theme-colour);
	display: inline-block;
	position: relative;
	border-bottom: 1px solid var(--theme-colour);
	@supports (text-decoration-skip-ink: auto) {
		// TODO move this back to coopstrap
		text-underline-offset: 2px;
		text-decoration-skip-ink: auto;
		text-decoration: underline;
		border-bottom: none;
		transition: text-decoration-color 150ms ease-in;
	}

	&:hover,
	&:focus,
	&:active {
		border-bottom-color: var(--text-colour);
		@supports (text-decoration-skip-ink: auto) {
			text-decoration-color: var(--text-colour);
		}
	}
}

@mixin base-site-link {
	color: white;
	border-bottom: 3px solid var(--accent-colour);
	padding-bottom: 8px;
	font-family: var(--heading-font);
	transition: color 150ms ease-in-out;
	display: inline-block;
	width: auto;
	transition: color 120ms ease-in-out;

	@supports (text-decoration-thickness: 3px) {
		text-decoration: underline;
		border-bottom: none;
		text-underline-offset: 5px;
		text-decoration-thickness: 3px;
		text-decoration-color: var(--accent-colour);
		padding: 0;
	}

	&:hover {
		color: var(--accent-colour);
	}
}
