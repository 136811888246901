html {
	width: 100%;
	overflow-x: hidden;
}

body {
	font-size: var(--fs-body);
	line-height: 1.5;
	color: white;
	min-height: 100vh;
	&.preload {
		* {
			transition: none !important;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: var(--fs-heading);
}

h2 {
	font-size: var(--fs-subheading);
}

h3 {
	font-size: var(--fs-body-lg);
}

h3 {
}

h4 {
}

h5 {
}

.responsive-object {
	position: relative;
	iframe,
	img {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.lede {
	font-size: var(--fs-body-lg);
	line-height: 1.6;
	@include to-md {
		font-size: 25px;
	}
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 0.4em;
	}
	> * + h1,
	> * + h2,
	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	h2,
	h3 {
		color: var(--theme-colour);
		font-family: var(--body-font);
		font-weight: bold;
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
		td {
			padding-top: $padding-rythm-sm / 4;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
	}

	&-image {
		padding-top: $padding-rythm-sm;
		position: relative;

		&.full-width {
			width: 100%;
			height: auto;
		}
	}
}

@include from-md {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}

		> p {
			line-height: 1.75;
		}

		&-image {
			&.left,
			&.right {
				max-width: 50%;
				display: block;
			}
			&.right {
				margin-left: auto;
			}
		}
	}
}
