.l-site-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: var(--p-rhythm);

	&--spiel {
		color: var(--accent-colour);
		width: 60%;
	}

	&--site-name {
		color: white;
		display: inline-block;
		width: auto;
		font-size: var(--fs-heading);
		font-family: var(--heading-font);
	}

	&--branding {
		position: relative;
		font-size: var(--fs-heading);
		font-family: var(--heading-font);
		font-weight: bold;
		width: auto;
		line-height: 1;
	}

	&--menu-trigger {
		position: absolute;
		width: auto;
		top: var(--p-rhythm);
		right: var(--p-rhythm);
		color: white;
		font-weight: bold;
		font-family: var(--heading-font);
	}

	&--buy-link {
		font-size: var(--fs-body-lg);
		margin-top: var(--p-rhythm-lg);
	}

	@include from-sm {
		&--spiel {
			color: var(--accent-colour);
			width: calc(100% / 3);
		}
	}
	@include to-lg {
		&__book {
			// To push the coverimage down
			position: relative;
		}
	}
}
