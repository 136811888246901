.l-streamfield {
	padding-top: var(--p-rhythm-lg);

	> * + * {
		margin-top: var(--p-rhythm-lg);
	}

	& > .dual-image-block,
	& > .responsive-object,
	& > .captioned-media {
		width: 100%;

		& + .rich-text {
			margin-top: calc(var(--p-rhythm-lg) * 1.2);
			@include to-md {
				margin-top: 100px;
			}
		}
	}

	.lede + .rich-text {
		margin-top: 0.4em;
	}

	&--publish-date {
		font-size: var(--fs-body-lg);
		font-weight: bold;
	}

	&__push {
		margin-top: 160px;
	}

	@include to-md {
		& > .looping-video {
			width: 100%;
			height: auto;
		}
		& > .blockquote {
			margin: 120px 0;
		}
	}

	@include from-md {
		& > * {
			width: 50%;
		}

		.lede + .rich-text {
			margin-top: 1em;
		}

		&__push {
			margin-top: calc(570px - var(--p-rhythm-lg));
		}
	}

	@include to-lg {
		&__book {
			margin-top: 0;
		}
	}
}
