body {
	background-color: var(--theme-colour);
	font-family: var(--body-font);

	&.white {
		background-color: white;
		color: var(--text-colour);

		&__no-header {
			.l-site-header {
				&--menu-trigger,
				&--site-name {
					color: var(--theme-colour);
				}
				&--spiel {
					color: var(--accent-alt-colour);
				}
			}
		}
	}
}
