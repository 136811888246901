.blockquote {
	margin: 0;
	padding: 0;

	&--body {
		font-style: italic;
		font-family: var(--heading-font);
		font-size: var(--fs-body-lg);
		line-height: 1.16;
		letter-spacing: 0.05rem;
	}
	&--citation {
		margin-top: 1.2em;
		color: var(--accent-colour);
		display: flex;
		align-items: center;
		line-height: 1;
		font-weight: bold;

		&:before {
			content: "";
			display: inline-block;
			width: 80px;
			height: 3px;
			background-color: var(--accent-colour);
			margin-right: 1em;
			margin-bottom: -4px;
		}
	}

	&__home {
		.blockquote {
			&--body {
				font-size: var(--fs-heading);
			}

			&--citation {
				font-size: var(--fs-body-lg);
			}
		}
	}

	@include to-md {
		&--citation {
			margin-top: 1em;
		}
	}
}

body.white {
	.blockquote {
		color: var(--theme-colour);
		&--citation {
			color: var(--theme-colour);
			&:before {
				background-color: var(--theme-colour);
			}
		}
	}
}
