.captioned-media {
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		font-size: 14px;
		padding-top: 22px;
	}

	@include from-md {
		&--caption {
			font-size: 16px;
			max-width: 50%;
		}
	}
}
