.l-home {
	&--about,
	& .blockquote {
		max-width: var(--home-max-width);
	}

	&--full-width-image {
		& > img {
			margin-bottom: calc(var(--p-rhythm) * 2);
			margin-left: calc(var(--p-rhythm) * -1);
			margin-right: calc(var(--p-rhythm) * -1);
			width: calc((var(--p-rhythm) * 2) + 100%);
		}
	}

	&--heading {
		font-size: var(--fs-subheading);
		margin-bottom: 40px;

		& + .news-listing {
			margin-top: calc(var(--p-rhythm) * -1);
		}
	}

	&--view-more {
		margin-top: 80px;
	}

	&--about {
		font-size: var(--fs-body-lg);
		font-family: var(--heading-font);
		line-height: 1.3;
	}

	&--other-name {
		position: relative;
		overflow: hidden;

		& > img {
			transition: transform 1000ms ease-in-out;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 50%;
			height: 100%;
			z-index: 1;
			background: linear-gradient(
				to right,
				rgba(var(--theme-colour-rgb), 0.75),
				rgba(var(--theme-colour-rgb), 0)
			);
		}

		& > span {
			z-index: 2;
			word-break: space;
			font-family: var(--heading-font);
			font-size: var(--fs-heading);
			color: white;
			font-weight: bold;
			position: absolute;
			left: var(--p-rhythm);
			height: 100%;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&:hover {
			& > img {
				transform: scale(1.03);
				transform-origin: 50% 50%;
			}
		}
	}

	.book-listing {
		&--summary {
			font-size: 17px;
			line-height: 1.5;
		}
	}

	&--section {
		margin-top: calc(var(--p-rhythm-lg) * 2);

		@include to-md {
			margin-top: 160px;
			&:first-child {
				margin-top: 30px;
			}
		}
	}

	@include from-md {
		&--others {
			display: flex;
		}
		&--view-more {
			margin-top: calc(var(--p-rhythm-lg) / 2);
		}
		&--heading {
			margin-bottom: 80px;
		}
	}
}
