.menu {
	transition: all 300ms ease-in-out;
	width: 100vw;
	height: 100vh;
	background: var(--theme-colour);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow-y: scroll;
	opacity: 0;
	pointer-events: none;

	&--nav {
		display: flex;
		flex-direction: column;
		text-align: right;
		position: absolute;
		bottom: var(--p-rhythm);
		right: var(--p-rhythm);
	}

	&--site-name {
		color: white;
		display: inline-block;
		width: auto;
		font-size: var(--fs-heading);
		font-family: var(--heading-font);
		line-height: 1;
		position: absolute;
		top: var(--p-rhythm);
		left: var(--p-rhythm);
	}

	&--item {
		opacity: 0;
		transform: translateX(10px);
		transition:
			opacity 200ms ease-in-out,
			transform 200ms ease-in-out;

		& + & {
			margin-top: calc(var(--p-rhythm) / 2);
		}

		.is-active & {
			opacity: 1;
			transform: translateX(0);

			@for $i from 1 through 15 {
				&:nth-child(#{$i}) {
					transition-delay: 300ms + $i * 160ms;
				}
			}
		}
	}

	&--link {
		@include base-site-link;
		font-size: #{pixelViewWidthMin(80, $factor: 10)};
	}

	&--close {
		position: absolute;
		width: auto;
		color: white;
		top: var(--p-rhythm);
		right: var(--p-rhythm);
		font-weight: bold;
		font-family: var(--heading-font);
	}
	&.is-active {
		opacity: 1;
		pointer-events: all;
	}

	@include to-md {
		&--nav {
			bottom: 120px;
		}

		&--item {
			& + & {
				margin-top: var(--p-rhythm);
			}
		}
	}
}
