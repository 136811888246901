.l-page {
	padding: var(--p-rhythm);

	@include to-lg {
		&--book-cover {
			padding: var(--p-rhythm);
			padding-bottom: 0;
			margin-bottom: calc(var(--p-rhythm) * -1);
			max-width: 600px;
			margin-left: auto;
		}
	}
	@include from-lg {
		&--book-cover {
			position: absolute;
			width: min(600px, calc(50% - (var(--p-rhythm) * 2)));
			top: calc(var(--p-rhythm) * 2);
			right: var(--p-rhythm);
			z-index: -1;
		}
	}

	&--header-image {
		position: relative;
		z-index: -1;
		&:after,
		&:before {
			content: "";
			position: absolute;
			height: 100%;
			top: 0;
		}

		&:after {
			left: 0;
			width: 100%;
			background: linear-gradient(
				to right,
				rgba(var(--theme-colour-rgb), 0.75),
				rgba(var(--theme-colour-rgb), 0)
			);
		}

		&:before {
			right: 0;
			width: calc((100% / 6) * 2);
			background: linear-gradient(
				to left,
				rgba(var(--theme-colour-rgb), 0.75),
				rgba(var(--theme-colour-rgb), 0)
			);
		}
	}

	&--error-message {
		// 404/500
		padding-top: var(--p-rhythm-lg);
		height: 50vh;

		@include to-md {
			padding-top: calc(var(--p-rhythm-lg) * 3);
		}
	}
}

@include from($bp-small) {
	.l-page {
	}
}
