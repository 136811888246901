.form-field {
	position: relative;
	width: 100%;

	&--field,
	&--label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&--field {
		border: 0 none;
		padding: 17px $padding-rythm-sm / 2 21px $padding-rythm-sm / 2;
		width: 100%;
		appearance: none;
		border-radius: 2px;
		border: 1px solid black;
		color: black;
	}

	&--boolean-group {
		> * + * {
			margin-top: $padding-rythm-sm / 2;
		}
	}

	&--label {
		margin-bottom: 12px;
		&-required {
			color: red;
		}
	}

	&--error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
	}
}

@include from-md {
	.form-field {
		font-size: 12px;
	}
}
