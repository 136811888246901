.dual-image-block {
	display: flex;
	flex-wrap: wrap;

	&--caption {
		font-size: 14px;
		margin-top: 22px;
	}

	@include to-md {
		&--media {
			width: 100%;
		}
	}

	@include from-md {
		&--caption,
		&--media {
			flex-basis: 50%;
		}

		&--caption {
			font-size: 16px;
			margin-top: 40px;
		}
	}
}
