/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1400px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 50px;
$padding-rythm-sm: 20px;

/**
 * Raw Colors
 */
$dark-grey: #58595b;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-medium * 1px);

/**
 * CSS Variables
*/
@function pixelViewWidthMin($pixels, $factor: 14) {
	@return "min(#{$pixels}px, #{$pixels / $factor}vw)";
}

:root {
	--theme-colour: #1b1c14;
	--accent-colour: #feedcd;
	--text-colour: white;

	--p-rhythm: 15px;
	--p-rhythm-lg: 30px;

	--body-font: "sweet-sans-pro";
	--heading-font: var(--body-font); // Overwrote by site styles

	--fs-heading: 30px;
	--fs-subheading: 22px;
	--fs-body-lg: 20px;
	--fs-body: 20px;

	--home-max-width: 100%;

	@include from-md {
		--fs-heading: #{pixelViewWidthMin(60)};
		--fs-subheading: #{pixelViewWidthMin(45)};
		--fs-body-lg: #{pixelViewWidthMin(30)};
		--p-rhythm: min(50px, 5vw);
		--p-rhythm-lg: min(160px, 8vw);
	}

	@include from-lg {
		--home-max-width: 80%;
	}
}
