.site-link {
	@include base-site-link();
	font-size: var(--fs-body);
}

body.white {
	.site-link {
		color: black;
		&:hover {
			color: var(--accent-colour);
		}
	}
}
