.featured-books {
	display: grid;
	row-gap: var(--p-rhythm);
	grid-template-columns: 1fr 1fr;

	--book-max: 70vw;

	&--title {
		color: var(--accent-colour);
		font-family: var(--heading-font);
		margin-top: calc(var(--p-rhythm) * 1.5);
		font-size: var(--fs-body-lg);
	}

	&--book {
		display: grid;
		grid-template-rows: var(--book-max) auto;
	}

	&--cover {
		max-height: var(--book-max);
		max-width: 100%;
	}

	&--summary {
		font-size: 17px;
	}

	& + .l-home--heading {
		margin-top: var(--p-rhythm);
	}

	@include to-md {
		&--book {
			align-items: end;
		}

		&--summary {
			display: none;
		}
	}

	@include from-md {
		display: grid;

		grid-template-columns: repeat(6, 1fr);
		row-gap: calc(var(--p-rhythm) * 3);

		--book-max: 25vw;

		&--title {
			margin-top: var(--p-rhythm);
			margin-bottom: 35px;
		}

		&--text {
			padding-right: 50px;
		}
	}

	@include from-lg {
		// --book-max: 460px;
	}
}
